import React from "react";
// import * as ReactDOM from 'react-dom/client';
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
//import 'bootstrap/dist/css/bootstrap.min.css';
import "./css/bootstrap.min.css";
import "./css/admin.min.css";
import "./css/fontawesome.min.css";
import "./styles/main.css";
import "./js/admin.min.js";

//include bootstrap js
import "bootstrap/dist/js/bootstrap.min.js";

import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import rootReducer from "./reducers";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { getQueryClient } from "./helper/QueryClient";
import { NotificationProvider } from "./components/context/NotificationContext";
var ReactDOM = require('react-dom/client');

const queryClient = getQueryClient();

const store = createStore(rootReducer);

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <NotificationProvider>
      <App />
      </NotificationProvider>
     
    </Provider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
