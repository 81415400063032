import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';

import Header from '../Common/Header'
import NewSidebar from '../new-sidebar'
import EmailList from './EmailList';
import Autoresponder from './Autoresponder';
import UnsubscribedListModal from './UnsubscribedListModal';
import SetEmailAddress from './SetEmailAddress';
import ConfirmationModal from '../Setting/ConfirmationModal';

import infoIcon from "../../images/dashboard/ic_error.svg";
import { Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import SuccessPopup from '../Setting/Sucesspopup';
import { userInfo } from '../../helper/service';
import { verifyEmail } from '../../services/settingsService';
import { set } from 'date-fns';


const settingTip = 'Wisdome has a built-in email autoresponder that you can use to send email. When members reply to these emails you send out, they will arrive into your third-party email provider';

const Email = () => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [isSetupOpen, setOpenSetup] = useState(false);
    const [showCreate, setShowCreate] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [email, setEmail] = useState('');
    const [confirmEmail, setConfirmEmail] = useState(false);

    const [verificationMessage, setVerificationMessage] = useState('');
    const [showVerification, setShowVerification] = useState(false);

    const resetState = () => {
        setConfirmEmail(false);
        setShowVerification(false);
    }

    useEffect(() => {
        const userId = localStorage.getItem('user_id');
        userInfo(userId).then(data => {
            if (data?.result?.validUser) {
                let user = data?.result?.validUser;
                if (user.emailSenderVerified) {
                    setShowCreate(true);
                    setIsVerified(true);
                } else {
                    setShowCreate(false);
                    setIsVerified(false);
                }
                let company = user.company && user.company.length > 0 ? user.company[0] : {};
                setEmail(company?.email);
            }
        }
        )
    }, []);

    const handleVerification = () => {
        setConfirmEmail(false);
        verifyEmail().then(result => {
            console.log(result, "data");
            if (result.status === 200) {
                setShowVerification(true);
                setVerificationMessage(result?.data?.message ?? "Error while verifying email, please try again later");
                if (result?.data?.success) {
                    setShowCreate(false);
                }
            } else {
                setVerificationMessage("Error while verifying email, please try again later");
            }
        })
    }

    return (
        <>
            {showVerification && <SuccessPopup message={verificationMessage} onCloseSuccessModel={() => resetState()} />}
            <Header />
            <NewSidebar />
            <div className="content-wrapper admin-courses bg-white">
                <div className='content-header mb-4'>
                    <section className='email-messaging'>
                        <div className='content-fluid px-3 py-2'>
                            <div className='row mt-4 p-2'>
                                <div className='col-lg-6 col-md-8 col-sm-10'>
                                    <h3 className='title'>Email Messaging</h3>
                                    <div className='text' >
                                        <p>
                                            Deliver messages to your members email inbox. Member will recieve email from <b>your company email address.</b>
                                            <p>Your email: <b>{email}</b></p>
                                        </p>
                                    </div>

                                    <div className='d-flex align-items-center flex-wrap gap-1 autoresponder'>
                                        {!isVerified &&
                                            <Link
                                                className='last-action mr-3'
                                                onClick={() => {
                                                    //setOpenSetup(true);
                                                    setConfirmEmail(true);
                                                }}>
                                                Click here to confirm your Email Address
                                            </Link>}

                                        {showCreate &&
                                            <button type="button" className='email-btn-custom mr-4 w-25 ' onClick={() => { navigate('/create-email') }}>
                                                <FontAwesomeIcon icon={faPlus} /> Create
                                            </button>
                                        }

                                        <button type="button"
                                            className='email-btn-custom w-25'
                                            onClick={() => {
                                                navigate('/email-setting');
                                            }}
                                        >
                                            Settings
                                        </button>

                                        <Tooltip title={settingTip} placement="top-start">
                                            <span className='info-img'>
                                                <img src={infoIcon} width={20} />
                                            </span>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                {
                    isVerified &&
                    <div>
                        {(isSetupOpen) && (<SetEmailAddress onClose={() => resetState()} />)}

                        {(!isSetupOpen) && (
                            <section className="courses-list">
                                <EmailList />
                            </section>
                        )}

                        {isOpen && <UnsubscribedListModal onClose={() => setIsOpen(false)} />}
                    </div>
                }

                {confirmEmail && <ConfirmationModal type='verify' title="Confirm your new sender email address" message={email}
                    onCancel={() => resetState()}
                    onConfirm={handleVerification} />
                }

            </div>
        </>
    )
}

export default Email