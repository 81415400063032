import React, {lazy } from "react";
import { BrowserRouter as Router, Routes, Route,Navigate, } from "react-router-dom";
import "./App.css";
import "./main.css";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./Loader";
import Login from "./components/login";
import Home from "./components/home";
import SubcribeStatus from "./pages/SubcribeStatus";
import SubsExpired from "./components/SubsExpired";
import MemberShips from "./components/Memberships/MemberShips";
import SubadminInvoices from "./components/Setting/SubadminInvoices";
import NewsSection from "./components/Setting/NewsSection";
import Email from "./components/Email/Email";
import CreateEmail from "./components/Email/CreateEmail";
import Autoresponder from "./components/Email/Autoresponder";
const ForgotPassword = lazy(() => import("./components/forgot-password"));
const WelcomePage = lazy(() => import("./components/welcome-page"));
const AccountVerification = lazy(() => import("./components/account-verification"));
const Tour = lazy(() => import("./components/tour"));
const Membership = lazy(() => import("./components/membership"));
const Preview = lazy(() => import("./components/preview"));
const Audiolibrary = lazy(() => import("./components/Audio/Audiolibrary"));
const Contentlibrary = lazy(() => import("./components/content/Contentlibrary"));
// import ContentSubFolder from './components/content/ContentSubFolder';
const Member = lazy(() => import("./components/Member/Member"));
const ChooseTemplate = lazy(() => import("./components/content/chooseTemplate"));
const EditTemplate = lazy(() => import("./components/content/editTemplate"));
const ContentPageSetting = lazy(() => import("./components/content/ContentPageSetting"));
const ContentPreview = lazy(() => import("./components/content/ContentPreview"));
const Videolibrary = lazy(() => import("./components/Video/Videolibrary"));
const Terms = lazy(() => import("./components/Terms"));
const StepTerms = lazy(() => import("./components/StepTerms"));
const FunctionalBased = lazy(() => import("./components/FunctionalBased"));
const Socialwall = lazy(() => import("./components/Socialwall/Socialwall"));
const MembershipSettings = lazy(() => import("./components/Setting/MembershipSettings"));
const AccountDetails = lazy(() => import("./components/Setting/AccountDetails"));
const CompanyDetails = lazy(() => import("./components/Setting/CompanyDetails"));
const Invoices = lazy(() => import("./components/Setting/Invoices"));
const NotificationsSettings = lazy(() => import("./components/Setting/NotificationsSettings"));
const GeneralSettings = lazy(() => import("./components/Setting/GeneralSettings"));
const Welcomepage = lazy(() => import("./components/Setting/Welcomepage"));
const CheckoutPage = lazy(() => import("./components/Setting/CheckoutPage"));
const PolicyPages = lazy(() => import("./components/Setting/PolicyPages"));
const Unsubscribe = lazy(() => import("./components/Setting/Unsubscribe"));
const Chat = lazy(() => import("./components/Chat"));
const Changepassword = lazy(() => import("./components/Setting/Changepassword"));
const CreatePassword = lazy(() => import("./components/CreatePassword"));
const Content = lazy(() => import("./components/content/Content"));
const Events = lazy(() => import("./components/events/Events"));
const Courses = lazy(() => import("./pages/courses/Courses"));
const Modules = lazy(() => import("./pages/courses/Modules"));
const Lessons = lazy(() => import("./pages/courses/Lessons"));
const AllNotifications = lazy(() => import("./components/Common/AllNotifications"));
const PaymentSettings = lazy(() => import("./components/Setting/PaymentSettings"));
const SalesPage = lazy(() => import("./components/Setting/SalesPage"));

// import ChallengesLibrary from "./components/Challenges/ChallengesLibrary";
const CoursesDetailPage = lazy(() => import("./components/Courses/CoursesDetailPage"));
const ChallengesLibrary = lazy(() => import("./components/Challenges/CoursesLibrary"));
const ChallengeDetailPage = lazy(() => import("./components/Challenges/CoursesDetailPage"));
const ContentPageSettingUpdate = lazy(() =>
  import("./components/content/ContentPageSettingUpdate")
);
const ContentEdit = lazy(() => import("./components/content/ContentEdit"));
const Resetpassword = lazy(() => import("./components/Resetpassword"));

//Content Library Sub Folder
const ContentLibrarySubFolder = lazy(() => import("./components/content/ContentLibrarySubFolder"));
const ContentLibrarySubFolderDetail = lazy(() =>
  import("./components/content/ContentLibrarySubFolderDetail")
);

const Signup = lazy(() => import("./components/signup"));
const Steps = React.lazy(() => import("./components/Step1"));
const Subscribe = React.lazy(() => import("./pages/subscribe"));
const Coupons = React.lazy(() => import("./components/Coupons/coupons"));
const AddCoupon = React.lazy(() => import("./components/Coupons/addAndEditCoupon"));

const VisibilityReport = React.lazy(() => import("./pages/visibilityReport"));

function SuspenseWrapper({ children }) {
  return <React.Suspense fallback={<Loader />}>{children}</React.Suspense>;
}

const routes = [
  { path: "/*", element: <Navigate to="/dashboard" /> },
  { path: "/coupons/*", element: <Coupons /> },
  { path: "/add-coupon/*", element: <AddCoupon /> },
  { path: "/signup/*", element: <Signup /> },
  { path: "/login/*", element: <Login /> },
  { path: "/dashboard/*", element: <Home /> },
  { path: "/forgot-password/*", element: <ForgotPassword /> },
  { path: "/welcome-page/*", element: <WelcomePage /> },
  { path: "/account-verification/*", element: <AccountVerification /> },
  { path: "/splash/*", element: <Tour /> },
  { path: "/memberships/*", element: <MemberShips /> },
  { path: "/3-step/*", element: <Steps /> },
  { path: "/membership/*", element: <Membership /> },
  { path: "/subscription-status/*", element: <SubsExpired /> },
  { path: "/preview/*", element: <Preview /> },
  { path: "/audio-library/*", element: <Audiolibrary /> },
  { path: "/video-library/*", element: <Videolibrary /> },
  { path: "/content-library/*", element: <Contentlibrary /> },
  { path: "/members/*", element: <Member /> },
  { path: "/email/*", element: <Email/>},
  { path: '/create-email/*', element: <CreateEmail/>},
  { path: "/choose-template/*", element: <ChooseTemplate /> },
  { path: "/edit-template/*", element: <EditTemplate /> },
  { path: "/content-page-setting/*", element: <ContentPageSetting /> },
  { path: "/content-preview/*", element: <ContentPreview /> },
  { path: "/terms-and-conditions/*", element: <Terms /> },
  { path: "/functional-components/*", element: <FunctionalBased /> },
  { path: "/social-wall/*", element: <Socialwall /> },
  { path: "/membership-settings/*", element: <MembershipSettings /> },
  { path: "/settings/account/*", element: <AccountDetails /> },
  { path: "/settings/company/*", element: <CompanyDetails /> },
  { path: "/settings/notifications/*", element: <NotificationsSettings /> },
  { path: "/general-settings/*", element: <GeneralSettings /> },
  { path: "/settings/welcome/*", element: <Welcomepage /> },
  { path: "/settings/news/*", element: <NewsSection /> },
  { path: "/checkout/*", element: <CheckoutPage /> },
  { path: "/invoices/*", element: <Invoices />},
  { path: "/Chat/*", element: <Chat /> },
  { path: "/settings/policy-pages/*", element: <PolicyPages /> },
  { path: "/settings/un-subscribe/*", element: <Unsubscribe /> },
  { path: "/messages/*", element: <Chat /> },
  { path: "/step3-terms-and-conditions/*", element: <StepTerms /> },
  { path: "/settings/change-password/*", element: <Changepassword /> },
  { path: "/create-password/*", element: <CreatePassword /> },
  { path: "/view-content/*", element: <Content /> },
  { path: "/events/*", element: <Events /> },
  { path: "/courses/*", element: <Courses /> },
  { path: "/courses/:id/*", element: <Modules /> },
  { path: "/courses/:courseid/modules/:moduleId/*", element: <Lessons /> },
  { path: "/courses-library/:id/*", element: <CoursesDetailPage /> },
  { path: "/courses-details/*", element: <CoursesDetailPage /> },
  { path: "/challenges-library/*", element: <ChallengesLibrary /> },
  { path: "/challenges-library/:id/*", element: <ChallengeDetailPage /> },
  { path: "/content-page-setting-update/*", element: <ContentPageSettingUpdate /> },
  { path: "/content-edit/*", element: <ContentEdit /> },
  { path: "/reset-password/*", element: <Resetpassword /> },
  { path: "/content-library-subfolder/*", element: <ContentLibrarySubFolder /> },
  { path: "/subscribe/*", element: <Subscribe /> },
  { path: "/content-library-subfolder-detail/*", element: <ContentLibrarySubFolderDetail /> },
  { path: "/subcribe-status/*", element: <SubcribeStatus /> },
  { path: "/notifications/*", element: <AllNotifications /> },
  { path: "/settings/payment/*", element: <PaymentSettings />},
  { path: "/settings/sales/*", element: <SalesPage />},
  { path: "/settings/billing/*", element: <SubadminInvoices/>},
  { path: "/visibility-report/*", element: <VisibilityReport /> },
  { path: '/email-setting/*', element: <Autoresponder/>},
];

function App() {
  return (
    <div className="App">
        <Router>
        <Routes>
          {routes.map(({path, element}, i) => {
            return (
              <Route
                key={i}
                path={path}
                element={
                  <SuspenseWrapper>
                    {element}
                  </SuspenseWrapper>
                }
              />
            );
          })}
        </Routes>
        </Router>
      <p className="app-version"
        style={{
          fontSize: "14px",
          textAlign: "right",
          position: "absolute",
          right: "0.5rem",
        }}
      >
        v{process.env.REACT_APP_VERSION}
      </p>
    </div>
  );
}

export default App;