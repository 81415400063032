import React, { Component } from 'react'
import { Modal, Button, Image } from "react-bootstrap";
import close from '../../images/dashboard/modal-close.svg';
import unsuccess from '../../images/audio/unsubscribe.svg';
import $ from 'jquery';

export default class UnsuccessPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
        };
    }

    openModal = () => this.setState({ isOpen: true });
    closeModal = () =>{
        this.props.setIsSuccess(false);
        this.setState({ isOpen: false });
    }
    render() {

        $(document).ready(function () {
            $('.sucessPopup').attr('id', 'playlistAddedModal');
        })

        return (
            <>
                <Modal className="sucessPopup" show={this.state.isOpen} onHide={this.closeModal} backdrop="static">
                    <Modal.Header className="justify-content-end align-items-end border-0 pb-0">
                        <button onClick={this.closeModal} type="button" className="close ml-5" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><img src={close} /></span>
                        </button>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <Image src={unsuccess} className="success-img img-fluid"></Image>
                        <h4 className='title'>{this.props.message}</h4>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}
