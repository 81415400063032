import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import EmailDetail from "./EmailDetail";
import SuccessPopup from "../Setting/Sucesspopup";

import {getLiveEmailSentToMember,getMemberScheduleEmail,deleteEmail} from '../../helper/service.ts';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter,faEllipsisVertical,faEnvelopeOpen ,faCopy, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import searchicon from "../../images/admin/search-dark.svg";

const EmailList = () => {
  const navigate = useNavigate();
  const [emailsToShow, setEmailsToShow] = useState(10);
  const [buttonShow,setButtonShow]=useState(emailsToShow);
  const [activeTab, setActiveTab] = useState("sentEmails");
  const [liveEmail,setLiveEmails] = useState('');
  const [scheduleEmail,setScheduleEmails]=useState('');
  const [showEmailDetail,setShowEmailDetailModal]=useState(false);
  const [emailDetail,setEmailDetail]=useState({});
  const [sentEmailCount,setSentEmailCount]=useState(0);
  const [schEmailCount,setSchEmailCount]=useState(0);
  const [isFilter,setIsFilter]=useState(true);
  const [originalSentEmails,setOrgSentEmails]=useState([]);
  const [originalSchEmails,setOrgSchEmails]=useState([]);
  const [isSuccess,setIsSuccess]=useState(false);
  const [mdlMessage,setMdlMessage]=useState('');

  useEffect(()=>{
    getSentEmails();
    getScheduledEmails();
  },[])

  const getSentEmails = async () =>{
    await getLiveEmailSentToMember().then(data=>{
      console.log(data.result,"******===live");

      const sortedData = sortEmails(data.result,"live"); //sorting data
      setOrgSentEmails(sortedData); //setting initial data
      iterateEmails(sortedData,"live"); //displaying in table
      setSentEmailCount(data.result.length);
    })
  }
  const getScheduledEmails = async () =>{
    await getMemberScheduleEmail().then(data=>{
      console.log(data.result,"*****===schedule");

      const sortedData =sortEmails(data.result,"schedule");
      setOrgSchEmails(sortedData);
      iterateEmails(sortedData,"schedule");
      setSchEmailCount(data.result.length);
    })
  }
  const openEmailDetailModal = (email) =>{
    setEmailDetail(email);
    setShowEmailDetailModal(true);
  }
  const handleEmaileDuplication = (emailSubj,emailMsg) =>{
    const emailSubject = `Duplicate/ ${emailSubj}`
    navigate('/create-email', {
      state: { emailSubject, emailMsg }
    });
  }
  const converDate = (date) =>{
    const originalDate = new Date(date);
    const selectedTimeZone = localStorage.getItem('websiteTimeZone');
    const convertedDate = originalDate.toLocaleString('en-US', { timeZone: selectedTimeZone, hour12: true });
    var sentDate = new Date(convertedDate);
    var textData = "";
    const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
    ];
    let strtDate = convertedDate.split('/')
    var months = parseInt(strtDate[0] - 1);
    textData = (strtDate[1]) + "-" + monthNames[months] + "-" + sentDate.getUTCFullYear();
    return textData;
  }
  const sortEmails = (data,type) =>{
    const sortedData = data.sort((a, b) => {
      if(type === 'live')
      {
        const dateA = a.emailSchedule === '' ? a.createdAt : a.emailSchedule;
        const dateB = b.emailSchedule === '' ? b.createdAt : b.emailSchedule;
        return new Date(dateA) - new Date(dateB);
      }
      else if(type === 'schedule'){
        return new Date(a.emailSchedule) - new Date(b.emailSchedule);
      }
      
    });
    return sortedData; 
  }
  const filterByDate = (value) => {
    if (value) {
      setIsFilter(prev => !prev);
      const sortedSentData = originalSentEmails.sort((a, b) => {
        const dateA = a.emailSchedule === '' ? a.createdAt : a.emailSchedule;
        const dateB = b.emailSchedule === '' ? b.createdAt : b.emailSchedule;
        return new Date(dateB) - new Date(dateA) ;
      });
      iterateEmails(sortedSentData, 'live');

      const sortedSchData = originalSchEmails.sort((a, b) => {
        return new Date(b.emailSchedule) - new Date(a.emailSchedule);
      });
      iterateEmails(sortedSchData, 'schedule');
    } 
    else {
      setIsFilter(prev => !prev);
      const data1 = sortEmails(originalSentEmails,"live");
      iterateEmails(data1, 'live');

      const data2 = sortEmails(originalSchEmails,'schedule');
      iterateEmails(data2, 'schedule');
    } 
  }
  const searchEmail = (event) =>{
    event.preventDefault();
    const { value } = event.target;
    if(value!=='')
    {
      
      const filteredResult = originalSentEmails.filter((ele) =>
        ele.emailSubject.toLowerCase().includes(value.toLowerCase())
      );
      iterateEmails(filteredResult,'live');
      
      const filteredSchResult = originalSchEmails.filter((ele) =>
        ele.emailSubject.toLowerCase().includes(value.toLowerCase())
      );
      iterateEmails(filteredSchResult,'schedule')
    }
    else{
      iterateEmails(originalSentEmails,'live')
      iterateEmails(originalSchEmails,'schedule')
    }
  }
  const handleDeleteEmail =async (id) => {
    await deleteEmail(id).then(res=>{
      if(res.status === 200){
        setIsSuccess(true);
        setMdlMessage('Email Deleted Successfully!!');
        getScheduledEmails();
      }
    })

  }

  const iterateEmails = (data,type) => {
    if(data)
    { 
      const displayData = data.map((email) =>{
        var originalDate='';
        if(type === 'live'){
          if(email.emailSchedule === '')
          {
            originalDate = converDate(email.createdAt);
          }
          else{
            originalDate = converDate(email.emailSchedule);
          }
        }
        else if (type === 'schedule'){
          originalDate = converDate(email.emailSchedule);
        }
        return(
          <tr key={email._id}>
            <td>{originalDate}</td>
            <td>{email.emailSubject}</td>
            <td>{email?.recipientsMember?.length>0?email?.recipientsMember?.length:0}</td>
            {/* <td>100%</td> */}
            <td className="">
            <div className="dropdown dropright">
              <span 
                  id="email-option"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  className="text-center pointer"
              >
                  <FontAwesomeIcon icon={faEllipsisVertical} color="#12aeb4" width={25} height={25} style={{height:'25px'}}/>
              </span>
                <div
                  className="dropdown-menu px-1 py-0"
                  aria-labelledby="email-option"
                  style={{ zIndex: 5}}
                >
                  <button
                    className="dropdown-item d-flex gap-2 align-items-center"
                    onClick={() => openEmailDetailModal(email)}
                  >
                  <FontAwesomeIcon icon={faEnvelopeOpen} color="#12aeb4" width={20} height={20}/>
                      Open
                  </button>
                  <button
                    className="dropdown-item d-flex gap-2 align-items-center"
                    onClick={() => handleEmaileDuplication(email.emailSubject,email.emailMessage)}
                  >
                  <FontAwesomeIcon icon={faCopy} color="#12aeb4" width={20} height={20}/>
                      Duplicate
                  </button>
                  {type === 'schedule' && <button
                    className="dropdown-item d-flex gap-2 align-items-center"
                    onClick={() => handleDeleteEmail(email._id)}
                  >
                  <FontAwesomeIcon icon={faTrashCan} color="#12aeb4" width={20} height={20}/>
                      Delete
                  </button>}
                </div>
              </div>
            </td>
          </tr>
        )
      })
      if(type === 'live'){
        setLiveEmails(displayData);
      }
      else if (type === 'schedule'){
        setScheduleEmails(displayData);
      }
    }
    else {
      if(type === 'live'){
        setLiveEmails("No Data Found");
      }
      else if (type === 'schedule'){
        setScheduleEmails("No Scheduled Email Found");
      }
    }
  }
  const loadMore = () => {
    if (emailsToShow >= sentEmailCount) {
      setEmailsToShow(10);
    } else {
      setEmailsToShow((prevMembersToShow) => prevMembersToShow + 10);
    }
  };
  return (
    <>
    {isSuccess && <SuccessPopup message={mdlMessage} closeSuccessModel={()=>{setIsSuccess(false);setMdlMessage('')}}/>}
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12 d-flex justify-content-end pr-2">
          {/* <button
            type="button"
            className="email-btn-custom mr-4"
            style={{ width: "10%" }}
            onClick={()=>filterByDate(isFilter)}
          >
            <FontAwesomeIcon icon={faFilter} /> Filter
          </button> */}
          <div className="search-email" style={{height:'42px'}}>
            <input
              type="text"
              name="searchEmail"
              className="form-control"
              placeholder="Search Email"
              onChange={searchEmail}
            />
            <span className="search-icon">
              <img src={searchicon} />
            </span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <ul className="nav nav-tabs" id="coursesTab" role="tablist">
            <li className="nav-item" role="presentation">
              <a
                className={`nav-link ${
                  activeTab === "sentEmails" ? "active" : ""
                }`}
                id="sentEmails-tab"
                data-toggle="tab"
                href="#sentEmails"
                role="tab"
                aria-controls="sentEmails"
                aria-selected={activeTab === "sentEmails"}
              >
                Sent Emails
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className={`nav-link ${
                  activeTab === "scheduledEmails" ? "active" : ""
                }`}
                id="scheduledEmails-tab"
                data-toggle="tab"
                href="#scheduledEmails"
                role="tab"
                aria-controls="scheduledEmails"
                aria-selected={activeTab === "scheduledEmails"}
              >
                Scheduled Emails
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div
              className="tab-pane fade show active"
              id="sentEmails"
              role="tabpanel"
              aria-labelledby="sentEmails-tab"
            >
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <td>Date Sent</td>
                      <td>Subject</td>
                      <td>Qty Recipients</td>
                      {/* <td>Open Rate</td> */}
                      <td>Actions</td>
                    </tr>
                  </thead>
                  <tbody>
                    {liveEmail.slice(0,emailsToShow)}
                  </tbody>
                </table>
              </div>
              <div className="flex d-flex text-align-center justify-content-center">
                {buttonShow < sentEmailCount &&
                    (
                      (sentEmailCount > emailsToShow  && (
                      <button className="load-more-button mb-3" onClick={loadMore}>
                        Load More
                      </button>
                    ))
                    ||
                    (sentEmailCount <= emailsToShow && (
                      <button className="load-more-button mb-3" onClick={loadMore}>
                        Show Less
                      </button>
                    ))
                    )
                }
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="scheduledEmails"
              role="tabpanel"
              aria-labelledby="scheduledEmails-tab"
            >
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <td>Date Scheduled</td>
                      <td>Subject</td>
                      <td>Qty Recipients</td>
                      {/* <td>Open Rate</td> */}
                      <td>Actions</td>
                    </tr>
                  </thead>
                  <tbody>
                    {scheduleEmail}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {showEmailDetail && <EmailDetail emailDetail={emailDetail} onClose={()=>setShowEmailDetailModal(false)}/>}
    </>
  );
};

export default EmailList;
