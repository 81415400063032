import { React, useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Table } from 'react-bootstrap';
import { fetchInvoices } from "../../helper/service";
import Loader from '../../Loader';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
const SubadminInvoicesComponent = () => {
    const [invoices, setInvoices] = useState([]);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        const fetchAndSetInvoices = async () => {
            setIsLoading(true);

            try {
                const data = await fetchInvoices();
                if (data.result) {
                    setInvoices(data.result);
                } else {
                    console.error("Unexpected data structure:", data);
                }
            } catch (error) {
                console.error("Error fetching invoices:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchAndSetInvoices();
    }, []);
    useEffect(() => {
    }, [invoices]);

    return (
        <>
            {isLoading ? <Loader /> : <></>}
            
            <Container fluid className="mt-2">
            <Row >
                <h3>Invoices</h3>
            </Row>
                <Row>
                    <div className="memberShip-list">
                        <div className="table-responsive mt-3">
                            <Table className='table'>
                                <thead>
                                    <tr>
                                        <th>Invoice ID</th>
                                        <th>Date/Time</th>
                                        <th>Status</th>
                                        <th>Amount</th>
                                        <th>Invoice Link</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {invoices.length > 0 ? invoices.map((invoice, index) => (
                                        <tr key={index}>
                                            <td>{invoice.invoiceId}</td>
                                            <td>{invoice.paidDate}</td>
                                            <td>{invoice.status}</td>
                                            <td>{`${invoice.amount} AUD`}</td>
                                            <td>
                                            <a href={invoice.invoiceLink} target="_blank" rel="noopener noreferrer">
                                                    View Invoice <OpenInNewIcon className="ml-1" />
                                                </a>
                                            </td>
                                        </tr>
                                    )) : (
                                        <tr>
                                            <td colSpan="5" className="text-center">No invoices available</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Row>
            </Container>
        </>
    )
}

export default SubadminInvoicesComponent;
